import React from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Theme, { Mq } from '../themes/default'
import ContactUsForm from '../molecules/contact-us-form'
import Layout from '../organisms/layout'
import HubSpotFormTag from '../organisms/hubSpotFormTag'
import { SubpageHero } from '../molecules/subpage-hero'
import SEO from '../organisms/seo'

const ContactUsContainer = styled.div`
  padding: 3rem 5%;
  height: 100%;
  overflow: hidden;

  ${Mq.md} {
    padding: 0 3% 6rem;
    margin: 0 5%;
  }
  ${Mq.xxl} {
    margin: 1% 12% 0;
  }
`

const ContactUs = () => {
  const onSubmission = () => navigate('/thank-you')

  return (
    <Layout>
      <SEO
        description="BiasSync is the first science-based assessment and development software solution designed to help organizations reduce the negative impact of unconscious bias."
        title="Contact Us | BiasSync"
      />
      <HubSpotFormTag formId={process.env.GATSBY_HUBSPOT_CONTACT_US_FORM_ID} />
      <SubpageHero title="Contact Us" text="Get started by letting us know about you." />
      <Fade bottom={true} cascade={true} distance={Theme.fadeDistance}>
        <ContactUsContainer>
          <ContactUsForm contactType="contact_us" onSubmission={onSubmission} ctaButtonText="Submit" />
        </ContactUsContainer>
      </Fade>
    </Layout>
  )
}

export default ContactUs
